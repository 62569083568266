/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/
 
export const NSDQOTUTPAgreementFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriber_Name: FullName,
        subscriber_FirstName: formData.FirstName,
        subscriber_LastName : formData.LastName,
        subscriber_Title: formData.JobTitle,
        subscriber_Telephone: formData.Mobile,
        subscriber_AddressOne: formData.AddressOne,
        subscriber_AddressTwo: formData.AddressTwo,
        subscriber_Email: formData.Email
    }
    return FormDataFields;
}

export const GeneralAgreementFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriber_userID: formData.OMS_UserID,
        subscriber_Name: FullName,
        subscriber_Address: formData.AddressOne,
        subscriber_City : formData.City,
        subscriber_Country: formData.Country,
        subscriber_Email: formData.Email,
    }
    return FormDataFields;
}

export const CBOEProfessionalFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriber_Name: FullName,
        subscriber_Email: formData.Email,
    }
    return FormDataFields;
}

export const NyseAgreementFormData = (formData: any) => {
    const FormDataFields = {
        subscriber_FirstName: formData.FirstName,
        subscriber_LastName:formData.LastName,
        subscriber_AddressOne: formData.AddressOne,
        subscriber_AddressTwo: formData.AddressTwo,
        subscriber_Title: formData.JobTitle
    }
    return FormDataFields;
}

export const OpraAgreementFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriber_Name: FullName,
        subscriber_Address: formData.AddressOne,
    }
    return FormDataFields;
}

export const BlueOceanAgreementFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriber_Name: FullName,
    }
    return FormDataFields;
}

export const NASDAQTotalViewAgreementFormData = (formData: any) => {
    const FormDataFields = {
        subscriber_FirstName:formData.FirstName,
        subscriber_Title: formData.JobTitle,
        subscriber_Address:formData.AddressOne,
        subscriber_Telephone: formData.Mobile,
        subscriber_Email: formData.Email
    }
    return FormDataFields;
}
