import { useState } from "react";

export const useInputHandlers = (initialValues: any) => {
    const [inputValues, setInputValues] = useState(initialValues);
  
    const [errors, setErrors] = useState({
        subscriber_signature: '',
        subscriber_Name: '', 
        subscriber_FirstName: '',       
        subscriber_LastName: '', 
        subscriber_Title: '',
        subscriber_Telephone: '',       
        subscriber_Email: '', 
        subscriber_copyTo: '',
        subscriber_AddressOne: '',
        subscriber_AddressTwo: '' 
      });

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
        return emailRegex.test(email);
      };
  
    // Handle input change for all fields
    const handleInputChange = (name: string, value: string) => {
      setInputValues({ ...inputValues, [name]: value });
  
      // Clear error if the user starts typing
      if (value.trim()) {
        setErrors({ ...errors, [name]: '' });
      }
    };
  
    // Handle error event for validation
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      // Check if field is empty and set appropriate error message
      if (!value.trim()) {
        setErrors({ ...errors, [name]: 'Please enter valid data' });
      } else if ((name === 'subscriber_Email' || name === 'subscriber_copyTo') && !isValidEmail(value)) {
        setErrors({ ...errors, [name]: 'Enter a valid email address' });
      }
    };
  
    return { inputValues, errors, handleInputChange, handleBlur };
  };
