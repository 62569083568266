/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

import { useEffect, useState } from 'react';
import '../../../assets/scss/index.scss';
import '../../../assets/themes/theme1-color-variables.css';
import '../../../assets/themes/theme2-color-variables.css';
import { useInputHandlers } from './NASDAQUTPNonproValidations';

const NASDAQUtpNonPro = ({ onChange, initialData, resData, setIsValidated }: any) => {
    const initialValues = {
        subscriber_signature: '',
        subscriber_Name: (resData?.FirstName + " " + resData?.LastName) || '',
        subscriber_FirstName: resData?.FirstName || '',
        subscriber_LastName: resData?.LastName || '',
        subscriber_Title: resData?.JobTitle || '',
        subscriber_Telephone: resData?.Mobile || '',
        subscriber_Email: resData?.Email || '',
        subscriber_copyTo: '',
        subscriber_AddressOne: resData?.AddressOne || '',
        subscriber_AddressTwo: resData?.AddressTwo || ''
    };

    const { inputValues, errors, handleInputChange, handleBlur } = useInputHandlers(initialValues);
    const [userCategory, setUserCategory] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const [agreeCheck, setAgreeCheck] = useState(false);
    const supportEmail = 'global@mubashertrade.com';
    const supportTelephone = '+97317005700';

    const onUserCategoryChange = (value: any) => {
        setUserCategory(value);
        if (value === 'professional') {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    };

    const onCheckBoxChange = (checked: any) => {
        setAgreeCheck(checked);  
    };

    useEffect(() => {
        const allFieldsFilled = Object.values(inputValues).every((value: any) => value.trim() !== '');
        const noErrors = Object.values(errors).every((error) => error === '');
        const isFormValid = allFieldsFilled && noErrors && agreeCheck && !showWarning;

        setIsValidated(isFormValid);
    }, [inputValues, errors, agreeCheck, showWarning]);



    return (
        <div className='txt-size-xs p-lg txt-color-body-shaded-2'>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossOrigin="anonymous" />
            <link rel="stylesheet" href="./styles.css" />
            <title />
            <div className='display-flex jc-center mt-sm'>
            <img src='../../images/UTP_logo.png' alt="UTP_Logo"  width="20%" />
            </div>
            <div className='mt-md'>
                <h2 className='display-flex txt-color-success txt-size-md txt-weight-extra-bold jc-center'>UTP PLAN SUBSCRIBER AGREEMENT</h2>
                <p className='mt-lg'>The Nasdaq Stock Market LLC (“Nasdaq”), as Administrator of the UTP Plan (“UTP Plan Administrator”), requires
                    all Subscribers to
                    the information described herein (“Information”) to sign the UTP Plan Subscriber Agreement (“Agreement”), or
                    its equivalent, in
                    order to receive the Information. <i> By completing the below section, the Subscriber agrees to the terms
                        and conditions set forth in
                        this UTP Plan Subscriber Agreement. </i></p>
                <div className='bg-color-paper-3 mlr-xl-n pl-xl'>
                    <p className='txt-color-success txt-size-sm mt-sm'>
                        SUBSCRIBER INSTRUCTIONS
                    </p>
                    <p>INDIVIDUAL SUBSCRIBER</p>
                    <div>
                        <div className='display-flex'>
                            <input
                                className="bg-color-paper-2"
                                type="radio"
                                checked={userCategory === 'professional'}
                                id="professional"
                                name="professional"
                                value="professional"
                                onChange={event => {
                                    onChange(event.target.name, event.target.value);
                                    onUserCategoryChange(event.target.value);
                                }}
                            />
                            <label className="ml-sm" htmlFor="professional">PROFESSIONAL Subscriber</label>
                        </div>

                        <div className='display-flex'>
                            <input
                                className="bg-color-paper-2"
                                type="radio"
                                checked={userCategory === 'nonProfessional'}
                                id="nonprofessional"
                                name="professional"
                                value="nonProfessional"
                                onChange={event => {
                                    onChange(event.target.name, event.target.value);
                                    onUserCategoryChange(event.target.value);
                                }}
                            />
                            <label className="ml-sm" htmlFor="nonprofessional" >NON-PROFESSIONAL Subscriber</label>
                        </div>
                    </div>
                    {showWarning && (
                        <div className="alert alert-warning mb-0 mt-1">
                            Customer type does not match your current profile. If you believe this is an error,
                            please contact the call center for more details. [{supportEmail} / {supportTelephone}]
                        </div>
                    )}

                    <p >(Only select if you qualify per the definition in Section 16).</p>

                    <div>
                        <div className="display-flex">
            
                                <div className='col-2'>
                                    <label htmlFor="representativeName">By* <span className='ml-xl pl-xl '>:</span></label>
                                </div>
                                <div className='col-10 pr-md'>
                                <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                ${errors.subscriber_signature ? 'b-xs-shaded-4' : ''}`} id="signature"
                                    placeholder="Signature" name="subscriber_signature"
                                    value={inputValues.subscriber_signature}
                                    onBlur={handleBlur}
                                    onChange={event => {
                                        onChange(event.target.name, event.target.value);
                                        handleInputChange(event.target.name, event.target.value);
                                    }} />
                                  
                        {errors.subscriber_signature && <span style={{ color: 'red' }}>{errors.subscriber_signature}</span>}
                        </div>
                        </div>
                        <div className="display-flex">
                            <div className="col-2">
                                <label htmlFor="representativeName">Name*<span className='ml-md pl-xl'>:</span></label>
                                </div>
                                <div className="col-10 pr-md">
                                <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-3 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2  
                                ${errors.subscriber_Name ? 'b-xs-shaded-4' : ''}`} id="signatoryName"
                                    placeholder="Print Name of Person Signing" name="subscriber_Name"
                                    value={inputValues.subscriber_Name}
                                    onBlur={handleBlur}
                                    onChange={event => {
                                        onChange(event.target.name, event.target.value);
                                        handleInputChange(event.target.name, event.target.value);
                                    }} />
                                    {errors.subscriber_Name && <span style={{ color: 'red' }}>{errors.subscriber_Name}</span>}
                                    </div>
                        </div>
                        <div className="display-flex">
                            <div className="col-2">
                                <label htmlFor="date">Date&nbsp;&nbsp;<span className='ml-lg pl-xl'>:</span></label>
                                </div>
                                <div className="col-10 pr-md">
                                <input className="p-sm txt-size-xs col-12 bg-color-paper-3 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 " placeholder="Date" type="text" name="date"
                                    value={initialData.dateTime || ''}
                                    disabled />
                                     </div>
                           
                        </div>
                    </div>
                </div>
                <hr className='mlr-xl-n'></hr>
                <div className="row mb-3">
                    <div className='txt-color-success txt-size-sm' >
                        VENDOR INSTRUCTIONS
                        <span className='txt-color-success txt-size-sm ml-sm'>
                            Please sign and complete the signature block below:
                        </span>
                    </div>
                    <div className="px-3 w-100 font-large font-weight-regular">
                        VENDOR USE ONLY (for Vendor/Data Provider Use Only)
                    </div>

                    {/* Vendor Section */}
                    <div className="w-100 pb-2 px-3">
                        <div className="display-flex">
                            <div className="w-25">Vendor</div>
                            <div className="w-75 border-bottom">
                                : GTN Group Holding Ltd
                            </div>
                        </div>
                        <div className="display-flex jc-end">
                            (Print Name of Vendor Firm)
                        </div>
                    </div>

                    {/* By Section */}
                    <div className="w-100 pb-2 px-3">
                        <div className="display-flex">
                            <div className="w-25">By</div>
                            <div className="w-75 border-bottom">:</div>
                        </div>
                        <div className="display-flex jc-end">
                            (Signature)
                        </div>
                    </div>

                    {/* Name of the Signatory Section */}
                    <div className="w-100 pb-2 px-3">
                        <div className="display-flex">
                            <div className="w-25">Name of the Signatory</div>
                            <div className="w-75 border-bottom">
                                :
                            </div>
                        </div>
                        <div className="display-flex jc-end">
                            (Print Name of the Person Signing on Behalf of the Vendor)
                        </div>
                    </div>

                    {/* Title Section */}
                    <div className="w-100 pb-2 px-3">
                        <div className="display-flex">
                            <div className="w-25">Title</div>
                            <div className="w-75 border-bottom">
                                :
                            </div>
                        </div>
                        <div className="display-flex jc-end">
                            Authorized Officer
                        </div>
                    </div>

                    {/* Date Section */}
                    <div className="w-100 pb-2 px-3">
                        <div className="display-flex">
                            <div className="w-25">Date</div>
                            <div className="w-75 border-bottom">
                                : {initialData.dateTime || ''}
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='mlr-xl-n'></hr>
                <h3 className='txt-color-success txt-size-md'>TERMS AND CONDITIONS</h3>
                <p className=' mt-md'>The Vendor and its agents may not modify or waive any term of this Agreement. Any attempt to modify this
                    Agreement, except
                    by Nasdaq, is void.</p>
                <div className=' mt-md'>
                    <div className='txt-align-justify'>
                        <b className='txt-color-heading-2'>1. USE OF DATA. </b>
                        Subscriber may not sell, lease, furnish or otherwise permit or provide access to the
                        Information to any other Person
                        or to any other office or place. Subscriber will not engage in the operation of any illegal business use
                        or
                        permit anyone else to use
                        the Information, or any part thereof, for any illegal purpose or violate any Nasdaq or Securities and
                        Exchange Commission (“SEC”)
                        Rule or other applicable law, rule or regulation. Subscriber may not present the Information rendered in
                        any
                        unfair, misleading or
                        discriminatory format. Subscriber shall take reasonable security precautions to prevent unauthorized
                        Persons
                        from gaining access
                        to the Information.
                        <div className='ml-md'>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>a. NON-PROFESSIONAL SUBSCRIBER - </b>For Non-Professional Subscribers, the Information is
                                licensed only for personal
                                use. By representing to Vendor that Subscriber is a Non-Professional Subscriber, or by
                                continuing to receive the
                                Information at a Non-Professional Subscriber rate, Subscriber is affirming to Vendor and to
                                Nasdaq that Subscriber meets
                                the definition of Non-Professional Subscriber as set forth in <span className='txt-color-heading-2 txt-weight-extra-bold txt-size-xs'>Section
                                    16</span> of this Agreement. A Non-Professional Subscriber
                                shall comply promptly with any reasonable request from Nasdaq for information regarding the
                                Non-Professional
                                Subscriber’s receipt, processing, display and redistribution of the Information.
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>b. PROFESSIONAL SUBSCRIBER - </b>For Professional Subscribers, the Information is licensed for
                                the internal business use
                                and/or personal use of the Professional Subscriber. Professional Subscribers may, on a
                                non-continuous basis, furnish
                                limited amounts of the Information to customers in written advertisements, correspondence or
                                other literature or during
                                voice telephonic conversations not entailing computerized voice, automated information inquiry
                                systems or similar
                                technologies. Upon request, Professional Subscribers shall make its premises available to Nasdaq
                                for physical inspection
                                of Vendor's Service and of Professional Subscriber's use of the Information (including review of
                                any records regarding use
                                of or access to the Information and the number and locations of all devices that receive
                                Information), all at reasonable
                                times, upon reasonable notice, to ensure compliance with this Agreement.
                            </div>
                        </div>
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>2. PROPRIETARY DATA. </b>
                        Nasdaq grants to Subscriber a nonexclusive, non-transferable license during the
                        term of the Agreement to
                        receive and use the Information transmitted to it by Vendor and thereafter to use such Information as
                        permitted under the terms
                        of this Agreement and/or the UTP Plan Requirements. Subscriber acknowledges and agrees that Nasdaq has
                        proprietary rights to
                        the Information that originates on or derives from markets regulated or operated by Nasdaq, and
                        compilation or other rights to
                        Information gathered from other sources. Subscriber further acknowledges and agrees that Nasdaq's
                        third-party information
                        providers have exclusive proprietary rights to their respective Information. In the event of any
                        misappropriation or misuse by
                        Subscriber or anyone who accesses the Information through Subscriber, Nasdaq or its third-party
                        information providers shall have
                        the right to obtain injunctive relief for its respective materials. Subscriber will attribute source as
                        appropriate under all the
                        circumstances.
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>3. PAYMENT. </b>
                        Subscriber shall assume full and complete responsibility for the payment of any taxes,
                        charges or assessments imposed
                        on Subscriber or Nasdaq (except for federal, state or local income taxes, if any, imposed on Nasdaq) by
                        any foreign or domestic
                        national, state, provincial or local governmental bodies, or subdivisions thereof, and any penalties or
                        interest relating to the
                        provision of the Information to Subscriber. Interest shall be due from the date of the invoice to the
                        time that the amount(s) that are
                        due have been paid. To the extent permitted by applicable law, Subscriber acknowledges and agrees that
                        the termination of the
                        Vendor's Service for failure to make payments shall not be considered an improper limitation of access
                        by Nasdaq. For Professional
                        Subscribers, if any payment is due directly to Nasdaq under this Agreement, payment in full is due
                        Nasdaq in immediately available
                        funds, in US Dollars by a check to Nasdaq, by electronic funds transfer to an institution of Nasdaq’s
                        choosing, within fifteen (15)
                        days of the date of an invoice, whether or not use is made of, or access is made to, the Information.
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>4. SYSTEM.</b>
                        Subscriber acknowledges that Nasdaq, in its sole discretion, may from time-to-time make
                        modifications to its system or
                        the Information. Such modifications may require corresponding changes to be made in Vendor's Service.
                        Changes or the failure to
                        make timely changes by Vendor or Subscriber may sever or affect Subscriber's access to or use of the
                        Information. Nasdaq shall not
                        be responsible for such effects. Nasdaq does not endorse or approve any equipment, Vendor or Vendor's
                        Service.
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>5. EXCLUSIVE REMEDY.</b>
                        Nasdaq shall endeavor to offer the Information as promptly and accurately as is
                        reasonably practicable. In
                        the event that the Information is not available as a result of failure by Nasdaq to perform its
                        obligations under this Agreement,
                        Nasdaq will endeavor to correct any such failure. If the Information is not available, is delayed, is
                        interrupted, is incomplete, is not
                        accurate or is otherwise materially affected for a continuous period of four (4) hours or more during
                        the time that Nasdaq regularly
                        transmits the Information due to the fault of Nasdaq (except for a reason permitted in this Agreement or
                        in Nasdaq’s agreement
                        with the Vendor), Subscriber’s or any other Person’s exclusive remedy against Nasdaq shall be:
                        <div className='ml-md'>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>a. </b>If Subscriber or any other Person continues to receive the Information or any other data and/or
                                information offered by
                                UTP Plan Subscriber Agreement 2019-1 3
                                Nasdaq, a prorated month’s credit of any monies due for the affected Information directly
                                to Nasdaq from Subscriber or,
                                if applicable, from said other Person, for the period at issue; or
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>b. </b>If Subscriber or any other Person no longer receives either the Information or any other data
                                and/or information offered
                                by Nasdaq, a prorated month’s refund of any monies due for the affected Information
                                directly to Nasdaq from Subscriber
                                or, if applicable, from said other Person, for the period at issue
                            </div>
                        </div>
                        <div className='mt-sm txt-align-justify'>
                            Such credit or refund shall, if applicable, be requested in writing to Nasdaq with all pertinent
                            details. Beyond the warranties stated
                            in this section, there are no other warranties of any kind — express, implied, statutory (including
                            without limitation, timeliness,
                            truthfulness, sequence, completeness, accuracy, freedom from interruption), implied warranties arising
                            from trade usage, course
                            of dealing, course of performance or the implied warranties of merchantability or fitness for a
                            particular use or purpose.</div>
                    </div>
                    <div className='mt-sm'>
                        <b className='txt-color-heading-2'>6. LIMITATION OF LIABILITY</b>
                        <div className='ml-md'>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>a. </b>
                                Except as may otherwise be set forth herein, Nasdaq shall not be liable to Subscriber, its
                                Vendor or any other Person for
                                indirect, special, punitive, consequential or incidental loss or damage (including, but not
                                limited to, trading losses, loss of
                                anticipated profits, loss by reason of shutdown in operation or increased expenses of operation,
                                cost of cover or other
                                indirect loss or damage) of any nature arising from any cause whatsoever, even if Nasdaq has
                                been advised of the
                                possibility of such damages.
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>b. </b>
                                Nasdaq shall not be liable to Subscriber or any other Person for any unavailability,
                                interruption, delay, incompleteness
                                or inaccuracy of the Information that lasts less than four (4) continuous hours during the time
                                that Nasdaq regularly
                                transmits the Information or if the Information is materially affected for less than four (4)
                                continuous hours during the
                                time that Nasdaq regularly transmits the Information.
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>c. </b>
                                If Nasdaq is, for any reason, held liable to Subscriber or to any other Person, whether in tort
                                or in contract, the liability
                                of Nasdaq within a single year of the Agreement (one year from the effective data of the
                                Agreement) is limited to an
                                amount of Subscriber’s damages that are actually incurred by Subscriber in reasonable reliance
                                (combined with the total
                                of all claims or losses of Subscriber’s Vendor and any other Person claiming through, on behalf
                                of or as harmed by
                                Subscriber) and which amount does not exceed the lesser of:
                                <div className='ml-md'>
                                    <div className='mt-sm txt-align-justify'>
                                        <b className='txt-color-heading-2'>i. </b>
                                        For Subscriber or any other person that continues to receive the Information or any
                                        other data and/or
                                        Information offered by Nasdaq, a prorated month’s <u>credit</u> of any monies due
                                        directly to Nasdaq from Subscriber
                                        or, if applicable, from any other Person, for the Information at issue during the period
                                        at issue, or if Subscriber or
                                        any other Person no longer receives either the Information or any other data and/or
                                        information offered by
                                        Nasdaq, a <u>refund</u> of any monies due directly to Nasdaq from Subscriber or, if
                                        applicable, from any other Person,
                                        for the Information at issue during the period at issue; or
                                    </div>
                                    <div className='mt-sm'>
                                        <b className='txt-color-heading-2'>ii. </b>$500
                                    </div>
                                </div>
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>d. </b>
                                This section shall not relieve Nasdaq, Subscriber or any other Person from liability for damages
                                that result from their
                                own gross negligence or willful tortious misconduct or from personal injury or wrongful death
                                claims
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>e. </b>
                                Subscriber and Nasdaq understand and agree that the terms of this section reflect a reasonable
                                allocation of risk and
                                limitation of liability.
                            </div>
                        </div>
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>7. DISCLAIMERS OF WARRANTIES. </b> Nasdaq and its third-party information providers make no warranties of
                        any kind — express,
                        implied or statutory (including without limitation, timeliness, truthfulness, sequence, completeness,
                        accuracy, freedom from
                        interruption), any implied warranties arising from trade usage, course of dealing, course of performance
                        or the implied warranties
                        of merchantability or fitness for a particular use or purpose or noninfringement.
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>8. THIRD-PARTY INFORMATION PROVIDERS’ LIMITATION OF LIABILITY. </b> Nasdaq’s third-party information
                        providers shall have no
                        liability for any damages for the accuracy of or for delays or omissions in any of the Information
                        provided by them, whether direct
                        or indirect, lost profits, special or consequential damages of the Subscriber or any other Person
                        seeking relief through Subscriber,
                        even if the third-party information providers have been advised of the possibility of such damages. In
                        no event will the liability of
                        the third-party information providers or their affiliates to Subscriber or any other Person seeking
                        relief through Subscriber pursuant
                        to any cause of action, whether in contract, tort or otherwise, exceed the fee paid by Subscriber or any
                        other Person seeking relief
                        through Subscriber, as applicable
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>9. CLAIMS AND LOSSES. </b> Subscriber will indemnify Nasdaq and hold Nasdaq and its employees, officers,
                        directors and other agents
                        harmless from any and all Claims or Losses imposed on, incurred by or asserted as a result of or
                        relating to: (a) any noncompliance
                        by Subscriber with the terms and conditions hereof; (b) any third-party actions related to Subscriber's
                        receipt and use of the
                        Information, whether authorized or unauthorized under the Agreement. Each party warrants and represents
                        and will indemnify and
                        UTP Plan Subscriber Agreement 2019-1 4
                        hold harmless (and in every case, Nasdaq shall be permitted to solely defend and settle) another party
                        (including Nasdaq) and their
                        officers, directors, employees and other agents, against any Claims or Losses arising from, involving or
                        relating to a claim of
                        infringement or other violation of an intellectual property right by the indemnifying party, its actions
                        or omissions, equipment or
                        other property. This right is conditioned on the indemnified party giving prompt written notice to the
                        indemnifying party (as does
                        not prejudice the defense) of the Claims or Losses and providing cooperation in the defense of the
                        Claims or Losses (without waiver
                        of attorney-client, work-product or other legal privilege, or disclosure of information legally required
                        to be kept confidential).
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>10. PERSONAL DATA. </b> Subscriber acknowledges that Nasdaq, in the course of providing services to
                        Subscriber, may process Personal
                        Data (as defined in the The Nasdaq Stock Market LLC Vendor Agreement for UTP Plan Services (“Vendor
                        Agreement”)) in the
                        performance of services or in support of its rights (including, but not limited to, its audit and usage
                        review rights) under the Vendor
                        Agreement. Subscriber shall provide to Vendor or its designee such Personal Data (including, but not
                        limited to, information
                        regarding Subscriber or, for Subscribers that are firms, information regarding individual users of the
                        Information) as reasonably
                        requested by Nasdaq to make Information available to Subscriber, perform Nasdaq’s services under the
                        Vendor Agreement, and/or
                        enforce Nasdaq’s rights (including, but not limited to, its audit and usage review rights) under the
                        Vendor Agreement, and Vendor
                        shall provide such information to Nasdaq or its designee. Provisions for the processing of such data are
                        set forth in the Vendor
                        Agreement. With respect to individuals whose Personal Data is processed by Nasdaq and/or its service
                        providers, the current
                        publicly-posted Privacy Policy identified on the UTP Plan website located at <a href="www.utpplan.com">www.utpplan.com</a> or its successor website, shall apply
                        to such processing. To the extent that the Subscriber is a legal entity established in the European
                        Economic Area (“EEA”), transfers
                        of Personal Data to a Vendor (or its designee) outside of the EEA in connection with this Agreement
                        shall be governed by the Data
                        Processing Addendum of the Vendor Agreement, which is incorporated herein by reference mutatis mutandis,
                        with the personal
                        data exporter being the Subscriber and the personal data importer being the Vendor. Transfers of
                        personal data from Vendor to
                        Nasdaq shall be governed by the relevant provisions of the Vendor Agreement.
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>11. TERMINATION.</b> Subscriber acknowledges that Nasdaq, when required to do so in fulfillment of
                        statutory obligations, may by
                        notice to Vendor unilaterally limit or terminate the right of any or all Persons to receive or use the
                        Information and that Vendor will
                        immediately comply with any such notice and will terminate or limit the furnishing of the Information
                        and confirm such compliance
                        by notice to Nasdaq. Any affected Person will have available to it such procedural protections as are
                        provided by the Act and
                        applicable rules thereunder. In addition to terminations permitted under the Vendor's agreement, this
                        Agreement may be
                        terminated by Subscriber with thirty (30) days written notice to Vendor and by Nasdaq with thirty (30)
                        days written notice either to
                        Vendor or Subscriber. Nasdaq may also alter any term of this Agreement with ninety (90) days written
                        notice either to Vendor or
                        Subscriber, and any use after such date is deemed acceptance of the new terms. In the event of
                        Subscriber breach, discovery of the
                        untruth of any representation of Subscriber, or where directed by the SEC in its regulatory authority,
                        Nasdaq may terminate this
                        Agreement with not less than three (3) days written notice to Subscriber provided either by Nasdaq or
                        Vendor.
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>12. AMENDMENTS/AGREEMENT. </b> Except as otherwise provided herein, no provision of this Agreement may be
                        amended, modified
                        or waived. No failure on the part of Nasdaq or Subscriber to exercise, no delay in exercising and no
                        course of dealing with respect
                        to any right, power or privilege under this Agreement shall operate as a waiver thereof, nor shall any
                        single or partial exercise of
                        any such right, power or privilege preclude any other or further exercise thereof or the exercise of any
                        other right, power or privilege
                        under this Agreement. If any of the provisions of this Agreement or application thereof to any
                        individual, entity or circumstance is
                        held invalid or unenforceable, the remainder of this Agreement, or the application of such terms or
                        provisions to individuals, entities
                        or circumstances other than those as to which they are held invalid or unenforceable, shall not be
                        affected thereby and each such
                        term and provision of this Agreement shall be valid and enforceable to the fullest extent permitted by
                        law. In the event of any
                        conflict between the terms of this Agreement and of the Vendor's agreement, the terms of this Agreement
                        shall prevail as between
                        Nasdaq and Subscriber.
                    </div>
                    <div className='mt-sm'>
                        <b className='txt-color-heading-2'>13. REQUIREMENTS OF SELF-REGULATORY ORGANIZATION; ACTIONS TO BE TAKEN IN FULFILLMENT OF STATUTORY
                            OBLIGATIONS. </b>
                        <div>
                            <div className='txt-align-justify'>
                                (a) Subscriber acknowledges that in the United States: (i) Nasdaq is registered with the SEC as
                                national securities exchanges pursuant
                                to Section 6 of the Act, and FINRA is registered with the SEC as a national securities
                                association pursuant to 15A of the Act; (ii) FINRA
                                and Nasdaq have a statutory obligation to protect investors and the public interest, and to
                                ensure that quotation information
                                supplied to investors and the public is fair and informative, and not discriminatory, fictitious
                                or misleading; (iii) Section 19(g)(1) of
                                the Act mandates that FINRA and Nasdaq comply with the UTP Plan Requirements; (iv) Nasdaq has
                                jurisdiction to enforce
                                compliance with certain of the UTP Plan Requirements; (v) FINRA has jurisdiction to enforce
                                compliance with certain of the UTP Plan
                                Requirements; and (vi) Nasdaq is obligated to offer terms that are not unreasonably
                                discriminatory between Subscribers, subject to
                                applicable UTP Plan Requirements. Accordingly, Subscriber agrees that Nasdaq, when required to
                                do so in fulfillment of its statutory
                                obligations, may, temporarily or permanently, unilaterally condition, modify or terminate the
                                right of any or all individuals or entities
                                to receive or use the Information. Nasdaq shall undertake reasonable efforts to notify
                                Subscriber of any such condition, modification
                                or termination, and Subscriber shall promptly comply with any such notice within such period of
                                time as may be determined in good
                                UTP Plan Subscriber Agreement 2019-1 5
                                faith by Nasdaq to be necessary, consistent with its statutory obligations. Any Person that
                                receives such a notice shall have available
                                to it such procedural protections as are provided to it by the Act and the applicable rules
                                thereunder.
                            </div>
                            <div className='txt-align-justify'>
                                (b) If Subscriber is a member of a Nasdaq market, then Subscriber expressly acknowledges and agrees
                                that (i) this Agreement does
                                not limit or reduce in any way Subscriber’s obligations and responsibilities as a member of any
                                applicable Nasdaq market; (ii) this
                                Agreement does not in any way alter the procedures or standards generally applicable to
                                disciplinary or other actions taken by
                                Nasdaq to enforce compliance with, or impose sanctions for violations of, the UTP Plan
                                Requirements; and (iii) the nonpayment of
                                amounts due under this Agreement could result in the suspension or cancellation of Subscriber's
                                membership in a Nasdaq market
                                in accordance with the UTP Plan Requirements.
                            </div>
                        </div>
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>14. GOVERNING LAWS; CONSTRUCTION. </b>
                        This Agreement shall be construed and enforced in accordance with,
                        and the validity and
                        performance hereof shall be governed by, the laws of the State of New York, without reference to
                        principles of conflicts of laws
                        thereof. Any dispute that cannot be amicably settled that arises out of this Agreement shall be referred
                        to arbitration and shall be
                        conducted in accordance with the rules of the American Arbitration Association. All such proceedings
                        shall be held in New York City,
                        NY, and shall be conducted in the English language, which shall also be the language of the documents.
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        <b className='txt-color-heading-2'>15. NOTICES; NOTIFICATION OF CHANGES. </b>
                        All notices and other communications (except for invoices)
                        required to be given in
                        writing under this Agreement shall be directed to the signatories or, in the alternative, to the
                        individuals identified in subsections
                        (a) and (b) below. Notices shall be deemed to have been duly given: (i) upon actual receipt (or date of
                        first refusal) by the parties,
                        or (ii) upon constructive receipt (or date of first refusal) if sent by certified mail, return receipt
                        requested, or any other delivery
                        method that actually obtains a signed delivery receipt, to the following addresses or to such other
                        address as any party hereto shall
                        hereafter specify by prior written notice to the other party or parties below, or (iii) upon posting the
                        notice or other communication
                        on the <a href="www.utpplan.com">www.utpplan.com</a> website or a successor site. If an email address is
                        provided, Nasdaq may, in lieu of the above, give notice
                        to or communicate with Subscriber by email addressed to the persons identified in subsection (a) or to
                        such other email address or
                        persons as Subscriber shall hereafter specify by prior written notice. By providing an email address,
                        Subscriber agrees that any
                        receipt received by Nasdaq from Subscriber’s service provider or internet computer server indicating
                        that the email was received
                        shall be deemed proof that Subscriber received the message. If Subscriber cannot see or printout all or
                        any portion of the message,
                        Subscriber agrees that it is Subscriber’s responsibility to contact Nasdaq at (301) 978–8080.
                        <div className="mt-md">
                            <div>
                                <div className="display-flex flex-column">
                                    <div>
                                        <p>(a) If to <b className='txt-color-heading-2'>Subscriber:</b></p>
                                    </div>
                                    <div className="form-group display-flex gap-xl mt-md">
                                        <div className='w-100'>
                                            <label >First Name*</label>
                                            <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                            ${errors.subscriber_FirstName ? 'b-xs-shaded-4' : ''}`} id="subscriberName"
                                                placeholder="Enter name" name="subscriber_FirstName"
                                                value={inputValues.subscriber_FirstName}
                                                onBlur={handleBlur}
                                                onChange={event => {
                                                    onChange(event.target.name, event.target.value);
                                                    handleInputChange(event.target.name, event.target.value);
                                                }} />
                                            {errors.subscriber_FirstName && <span style={{ color: 'red' }}>{errors.subscriber_FirstName}</span>}
                                        </div>
                                        <div className='w-100'>
                                            <label >Last Name*</label>
                                            <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                            ${errors.subscriber_LastName ? 'b-xs-shaded-4' : ''}`} id="subscriberName"
                                                placeholder="Enter name" name="subscriber_LastName"
                                                value={inputValues.subscriber_LastName}
                                                onBlur={handleBlur}
                                                onChange={event => {
                                                    onChange(event.target.name, event.target.value);
                                                    handleInputChange(event.target.name, event.target.value);
                                                }} />
                                            {errors.subscriber_LastName && <span style={{ color: 'red' }}>{errors.subscriber_LastName}</span>}
                                        </div>
                                    </div>
                                    <div className="form-group display-flex gap-xl mt-md">
                                        <div className="w-100">
                                            <label>Title*</label>
                                            <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                            ${errors.subscriber_Title ? 'b-xs-shaded-4' : ''}`} id="title"
                                                placeholder="Enter title" name="subscriber_Title"
                                                value={inputValues.subscriber_Title}
                                                onBlur={handleBlur}
                                                onChange={event => {
                                                    onChange(event.target.name, event.target.value);
                                                    handleInputChange(event.target.name, event.target.value);
                                                }} />
                                            {errors.subscriber_Title && <span style={{ color: 'red' }}>{errors.subscriber_Title}</span>}
                                        </div>
                                        <div className="w-100">
                                            <label >Address 1*</label>
                                            <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                            ${errors.subscriber_AddressOne ? 'b-xs-shaded-4' : ''}`} id="address"
                                                placeholder="Address 1" name="subscriber_AddressOne"
                                                value={inputValues.subscriber_AddressOne}
                                                onBlur={handleBlur}
                                                onChange={event => {
                                                    onChange(event.target.name, event.target.value);
                                                    handleInputChange(event.target.name, event.target.value);
                                                }} />
                                            {errors.subscriber_AddressOne && <span style={{ color: 'red' }}>{errors.subscriber_AddressOne}</span>}
                                        </div>
                                    </div>
                                    <div className="form-group display-flex gap-xl mt-md">
                                        <div className="w-100">
                                            <label >Address 2*</label>
                                            <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                            ${errors.subscriber_AddressTwo ? 'b-xs-shaded-4' : ''}`} id="address"
                                                placeholder="Address 2" name="subscriber_AddressTwo"
                                                value={inputValues.subscriber_AddressTwo}
                                                onBlur={handleBlur}
                                                onChange={event => {
                                                    onChange(event.target.name, event.target.value);
                                                    handleInputChange(event.target.name, event.target.value);
                                                }} />
                                            {errors.subscriber_AddressTwo && <span style={{ color: 'red' }}>{errors.subscriber_AddressTwo}</span>}
                                        </div>
                                        <div className="w-100">
                                            <label >Telephone*</label>
                                            <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2
                                             ${errors.subscriber_Telephone ? 'b-xs-shaded-4' : ''}`} id="telephone"
                                                placeholder="Enter telephone" name="subscriber_Telephone"
                                                value={inputValues.subscriber_Telephone}
                                                onBlur={handleBlur}
                                                onChange={event => {
                                                    onChange(event.target.name, event.target.value);
                                                    handleInputChange(event.target.name, event.target.value);
                                                }} />
                                            {errors.subscriber_Telephone && <span style={{ color: 'red' }}>{errors.subscriber_Telephone}</span>}
                                        </div>
                                    </div>
                                    <div className="form-group display-flex gap-xl mt-md">
                                        <div className="w-100">
                                            <label >Fax#</label>
                                            <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2`} id="fax"
                                                placeholder="Enter fax" name="subscriber_Fax" onChange={event => { onChange(event.target.name, event.target.value); }} />
                                        </div>
                                        <div className="w-100">
                                            <label >Email*</label>
                                            <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                            ${errors.subscriber_Email ? 'b-xs-shaded-4' : ''}`} id="email"
                                                placeholder="Enter email" name="subscriber_Email"
                                                value={inputValues.subscriber_Email}
                                                onBlur={handleBlur}
                                                onChange={event => {
                                                    onChange(event.target.name, event.target.value);
                                                    handleInputChange(event.target.name, event.target.value);
                                                }} />
                                            {errors.subscriber_Email && <span style={{ color: 'red' }}>{errors.subscriber_Email}</span>}
                                        </div>
                                    </div>
                                    <div className="form-group display-flex mt-md">
                                        <div className="w-100">
                                            <label>With, in the event of notices of dispute or
                                                default,
                                                a required copy to:*</label>
                                            <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                                ${errors.subscriber_copyTo ? 'b-xs-shaded-4' : ''}`} id="copyToEmail" name="subscriber_copyTo"
                                                value={inputValues.subscriber_copyTo}
                                                placeholder='abc@gtngroup.com'
                                                onBlur={handleBlur}
                                                onChange={event => {
                                                    onChange(event.target.name, event.target.value);
                                                    handleInputChange(event.target.name, event.target.value);
                                                }} />
                                            {errors.subscriber_copyTo && <span style={{ color: 'red' }}>{errors.subscriber_copyTo}</span>}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className='display-flex mt-sm'>
                                        <input className='bg-color-paper-2' type="checkbox" id="iAgreeCheckbox" name="checkbox" value="iAgree"
                                        checked={agreeCheck} 
                                        onChange={event => {
                                            onChange(event.target.name, event.target.value);
                                            onCheckBoxChange(event.target.checked);
                                        }}/>
                                        <label className='ml-sm'>I agree to the terms specified in the agreement</label>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-sm'>
                                <p style={{ marginBottom: '5px' }}>(a) If to <b className='txt-color-heading-2'>UTP Plan Administrator:</b> </p>
                                <table>
                                    <tr >
                                        <td>Name</td>
                                        <td className='b-b-sm-shaded-1 w-100'>: UTP Plan Administrator</td>
                                    </tr>
                                    <tr>
                                        <td>Title:</td>
                                        <td className='b-b-sm-shaded-1 w-100'>:&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>Address</td>
                                        <td className='b-b-sm-shaded-1 w-100'>: 805 King Farm Boulevard, Rockville, MD 20850</td>
                                    </tr>
                                    <tr>
                                        <td>Telephone#</td>
                                        <td className='b-b-sm-shaded-1 w-100'>: +1 301 978 8080</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td className='b-b-sm-shaded-1 w-100'>: admin@utpplan.com</td>
                                    </tr>
                                </table>

                                <p className='mt-sm'>With, in the event of notices of default or dispute, a required copy to: </p>
                                <hr></hr>
                                <ul />
                                <p><b className='txt-color-heading-2'>The Nasdaq Stock Market, LLC</b> </p>
                                <p>Office of General Counsel</p>
                                <p>805 King Farm Boulevard</p>
                                <p>Rockville, MD 20850</p>
                            </div>
                        </div>
                    </div>
                    <div className='mt-md'>
                        <b className='txt-color-heading-2'>16. DEFINITIONS. </b>
                        <div className='ml-md'>
                            <div className='mt-sm'>
                                <b className='txt-color-heading-2'>Act</b> shall mean the Securities Exchange Act of 1934.
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>Affiliate</b> shall mean any individual, corporation, company, partnership, limited
                                partnership, limited liability company, trust,
                                association or other entity that, directly or indirectly through one or more intermediaries,
                                controls, is controlled by, or is under
                                common control with such party.
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>Claims or Losses - </b> Any and all liabilities, obligations, losses, damages, penalties,
                                claims, actions, suits, costs, judgments, settlements
                                and expenses of whatever nature, whether incurred by or issued against an indemnified party or a
                                third party, including, without
                                limitation, (a) indirect, special, punitive, consequential or incidental loss or damage,
                                (including, but not limited to, trading losses,
                                loss of anticipated profits, loss by reason of shutdown in operation or increased expenses of
                                operation or other indirect loss or
                                UTP Plan Subscriber Agreement 2019-1 6
                                damage), and (b) administrative costs, investigatory costs, litigation costs and auditors' and
                                attorneys' fees and disbursements
                                (including in-house personnel).
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>Information</b> shall mean certain market data and other data disseminated that has been
                                collected, validated, processed, and
                                recorded by the System or other sources made available for transmission to and receipt from
                                either a Vendor or from Nasdaq
                                relating to: a) eligible securities or other financial instruments, markets, products, vehicles,
                                indicators, or devices; b) activities of a
                                Nasdaq Company; c) other information and data from a Nasdaq Company. Information also includes
                                any element of Information as
                                used or processed in such a way that the Information can be identified, recalculated or
                                re-engineered from the processed
                                Information or that the processed Information can be used as a substitute for Information
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>Nasdaq</b> shall collectively mean The Nasdaq Stock Market LLC, a Delaware limited liability
                                company and its subsidiaries and Affiliates
                                (collectively “Nasdaq”).
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>UTP Plan Requirements - </b> All (i) rules, regulations, interpretations, decisions,
                                opinions, orders and other requirements of the SEC;
                                (ii) the rules and regulations, disciplinary decision and rule interpretations applicable to UTP
                                (iii) the decisions, policies,
                                interpretations, operating procedures, specifications, requirements, and other documentation by
                                Nasdaq, as Administrator of the
                                UTP Plan, that is regulatory or technical in nature (including, but not limited to, user guides)
                                published on the UTP Plan website
                                located at <a href="www.utpplan.com">www.utpplan.com</a> or another website accessible by and
                                made known to Vendor; and (iv) all other applicable laws,
                                statutes, rules, regulations, orders, decisions, interpretations, opinions, and other
                                requirements, whether promulgated by the
                                United States or any other applicable jurisdiction (including in the area of intellectual
                                property); and (v) the successors, as they may
                                exist at the time, of the components of the UTP Plan Requirements.
                            </div>
                            <div className='mt-sm'>
                                <b className='txt-color-heading-2'>Or - </b> Includes the word "and."
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>Person - </b>Any natural person, proprietorship, corporation, partnership or other entity
                                whatsoever.
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>Subscriber - </b> When it appears alone, the word "Subscriber" encompasses all
                                Non-Professional and Professional Subscribers. All
                                Subscribers are deemed Professional unless they are qualified as Non-Professional.
                                <div className='ml-md'>
                                    <div className='mt-sm'>
                                        <b className='txt-color-heading-2'>Non-Professional Subscriber - </b> Any natural person who is NOT:
                                        <div style={{ listStyleType: 'lower-alpha' }}>
                                            <div className='mt-sm txt-align-justify'>
                                                a) registered or qualified in any capacity with the SEC, the Commodities Futures
                                                Trading Commission, any state securities
                                                agency, any securities exchange or association or any commodities or futures
                                                contract market or association;
                                            </div>
                                            <div className='mt-sm txt-align-justify'>
                                                b) engaged as an "investment adviser" as that term is defined in
                                                <a className='txt-color-body-shaded-1' href="https://www.investor.gov/introduction-investing/investing-basics/role-sec/laws-govern-securities-industry#invadvact1940">Section
                                                    202(a)(11) of the Investment Advisers Act of
                                                    1940</a> (whether or not registered or qualified under that Act); or
                                            </div>
                                            <div className='mt-sm txt-align-justify'>
                                                c) employed by a bank or other organization exempt from registration under federal
                                                or state securities laws to perform
                                                functions that would require registration or qualification if such functions
                                                were performed for an organization not so
                                                exempt.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-sm txt-align-justify'>
                                        <b className='txt-color-heading-2'>Professional Subscriber - </b>
                                        All other persons who do <u>not</u> meet the
                                        definition of Non-Professional Subscriber.
                                    </div>
                                </div>
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>System</b> shall mean Vendor and its Affiliates
                                as identified in writing to Nasdaq.
                            </div>
                            <div className='mt-sm txt-align-justify'>
                                <b className='txt-color-heading-2'>Vendor's Service - </b>
                                The service from a Vendor, including the data processing equipment,
                                software and communications facilities related thereto, for receiving,
                                processing, transmitting, using and disseminating the Information to or by Subscriber.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NASDAQUtpNonPro;
