/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

const searchEmptyFields = (formData: any, requiredFields: string[]) => {
    const emptyFields = [];

    for (const field of requiredFields) {
        if (!(field in formData) || formData[field] === "") {
            emptyFields.push(field);
        }
    }
    return emptyFields;
};

export const validateFormDataGeneralAgreementResponse = (formData: any) => {
    const requiredFields = [
        "subscriber_userID",
        "subscriber_Signature",
        "subscriber_Name",
        "subscriber_Address",
        "subscriber_City",
        "subscriber_Country",
        "subscriber_Email",
        "subscriber_internalReference",
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }

    return { success: true, data: formData };
}

export const validateFormDataNASDAQUtpNonProResponse = (formData: any) => {
    const requiredFields = [       
        "professional",
        "subscriber_signature",
        "subscriber_Name",
        "subscriber_FirstName",
        "subscriber_LastName",
        "subscriber_Title",
        "subscriber_Telephone",
        "subscriber_Email",
        "subscriber_copyTo",
        "subscriber_AddressOne",
        "subscriber_AddressTwo",
        "checkbox"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);
    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataBlueOceanResponse = (formData: any) => {
    const requiredFields = [
        "subscriber_Name",
        "subscriber_signature",
        "subscriber_type"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataNASDAQTotalViewResponse = (formData: any) => {
    const requiredFields = [
        "subscriber_FirstName",
        "subscriber_Title",
        "subscriber_Address",
        "subscriber_Telephone",
        "subscriber_Email",
        "subscriber_copyTo",
        "subscriber_signature"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataOpraSubscriberResponse = (formData: any) => {
    const requiredFields = [
        "subscriber_Name",
        "subscriber_Address",
        "section1_0_agree",
        "section2_0_agree",
        "section2_1_agree",
        "section2_2_agree",
        "section2_3_agree"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);
    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataNyseSubscriberResponse = (formData: any) => {
    const requiredFields = [
        "subscriber_FirstName",
        "subscriber_LastName",
        "subscriber_AddressOne",
        "subscriber_AddressTwo",
        "subscriber_Title",
        "employer_occupation",
        "employer_Name",
        "employer_Street",
        "employer_City",
        "subscriber_EmploymentFunctions",
        "certificationOk",
        "acceptedAndAgreedSec2",
        "acceptedAndAgreedSec1"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataCBOEProfessionalResponse = (formData: any) => {
    const requiredFields = [
        "subscriber_Name",
        "subscriber_Email",
        "subscriber_signature",
        "subscriber_type"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};