import { useState } from "react";

export const useInputHandlers = (initialValues: any) => {
    const [inputValues, setInputValues] = useState(initialValues);
  
    const [errors, setErrors] = useState({
        subscriber_FirstName: '',       
        subscriber_LastName: '', 
        subscriber_AddressOne: '',
        subscriber_AddressTwo: '',       
        subscriber_Title: '',
        subscriber_EmploymentFunctions: '',
        employer_occupation: '',
        employer_Name: '',
        employer_Street: '',
        employer_City: '',
      });
  
    // Handle input change for all fields
    const handleInputChange = (name: string, value: string) => {
      setInputValues({ ...inputValues, [name]: value });
  
      // Clear error if the user starts typing
      if (value.trim()) {
        setErrors({ ...errors, [name]: '' });
      }
    };
  
    // Handle error event for validation
    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
    
      if (name === 'employer_occupation') {
        // Check if the user has selected a valid option (not the default empty value)
        if (!value || value === "") {
          setErrors({ ...errors, [name]: 'Please select an option' });
        }
      } else {
        // For other text fields
        if (!value.trim()) {
          setErrors({ ...errors, [name]: 'Please enter valid data' });
        }
      }
    };
  
    return { inputValues, errors, handleInputChange, handleBlur };
  };
