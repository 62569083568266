import { Message } from "../../enums/message.enum";
import { createAllAgreedMessageObject } from "../../util/helper/ObjectDataCreationHelper";
import { sendMessageToParentApp } from "../../util/UtilService";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import {
  allAgreementSignedUserConfirm,
  cancelConfirmation,
} from "../../service/AgreementViewService";
import { useState } from "react";

const Confirmation = ({ subscriptionInfo }: { subscriptionInfo: any }) => {
  const [loaderConfirm, setLoaderConfirm] = useState(false);
  const [loaderCancel, setLoaderCancel] = useState(false);
  console.log("subscriptionInfo for all signed user", subscriptionInfo);

  const handleConfirm = async () => {
    setLoaderConfirm(true);

    const allSignedUserSubscriptionInfo = {
      ...subscriptionInfo,
      allSignedDirectSubscription: true,
    };

    try {
      // Add direct subscription request for the all required agreements signed user
      const response = await allAgreementSignedUserConfirm(
        allSignedUserSubscriptionInfo
      );
      console.log(" confimation response:", response);

      if (response?.success) {
        const allAgreedMessage = createAllAgreedMessageObject(
          subscriptionInfo.subscribeUserData
        );
        console.log("allAgreedMessage for confimation:", allAgreedMessage);
        // Message sender to the parent application
        sendMessageToParentApp(allAgreedMessage);
      } else {
        console.error("Subscription confirmation failed:", response?.message);
        setLoaderConfirm(false);
      }
    } catch (error) {
      console.error("Error confirming subscription:", error);
      setLoaderConfirm(false);
    } finally {
      setLoaderConfirm(false);
    }
  };

  const handleConfirmationCancel = async () => {
    setLoaderCancel(true);

    const allSignedUserSubscriptionCancelInfo = {
      ...subscriptionInfo,
      allSignedDirectSubscription: true,
    };

    try {
      // Cancel direct subscription request for the all required agreements signed user
      const response = await cancelConfirmation(
        allSignedUserSubscriptionCancelInfo
      );

      console.log("all signed user subscription cancel response", response);

      // Check if the response was successful
      if (response?.success) {
        // Message sender to the parent application
        sendMessageToParentApp({ messageType: Message.cancelSubmission });
      } else {
        console.error("Subscription confirmation cancel failed:", response);
        setLoaderCancel(false);
      }
    } catch (error) {
      console.error("Error confirming subscription:", error);
      setLoaderCancel(false);
    } finally {
      setLoaderCancel(false);
    }
  };

  return (
    <div className="width-100-vw height-100-vh bg-color-paper-1">
      <div className="position-absolute top-50 start-50 translate-middle b-sm-shaded-5">
        <div className="b-b-sm-shaded-5 height-200-px ">
          <div className="txt-size-md text-white text-center bg-color-paper-5 ptb-md">
            Confirmation
          </div>
          <div className="txt-size-sm text-center p-xl txt-color-success d-flex flex-column align-items-center">
            <ThumbUpOffAltIcon color="success" style={{ fontSize: 80 }} />
            You have already signed all the required agreements.Confirm your
            subscription request.
          </div>
        </div>
        <div className="display-flex jc-center ptb-md">
          <button
            className="txt-color-body-shaded-4 b-sm-shaded-5 bg-color-paper-1 p-ms txt-size-xs"
            onClick={handleConfirmationCancel}
            disabled={loaderCancel}
          >
            {loaderCancel ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Cancel"
            )}
          </button>
          <button
            className="p-sm ml-lg txt-size-xs txt-color-heading-1 bg-color-paper-2 b-none"
            onClick={handleConfirm}
            disabled={loaderConfirm}
          >
            {loaderConfirm ? (
              <>
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
               Confirming...
            </>
            ) : (
              "Confirm Subscription"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Confirmation;
