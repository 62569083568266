/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/
export enum AgreementID {
    GENERAL = '1',
    NASDAQ_NP = '2',
    NYSE_AMEX_NP = '3',
    NASDAQ_TV_NP = '4',
    OPRA_NP = '5',
    CBOE_NP = '6',
    BLUE_OCEAN = '7'
}